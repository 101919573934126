import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MainImageList from './MainImageList';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';


import Image from '../Images/Image28.png'; // Import using relative path



const styles = {
    paperContainer: {
        backgroundImage: `url(${Image})`
    }
};


function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                ООО "ГИСС"
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();


function Example(props) {
    var items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!",
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
        },
        {
            name: "Random Name #3",
            description: "Hello PETER!",
        }
    ]

    return (
        <Carousel>
            {
                items.map((item, i) => <Item key={i} item={item}  />                )
            }
        </Carousel>

    )
}

function Item(props) {
    return (
        <Paper>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>

            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper>
    )
}



export default function Album() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                        height: 800
                    }}
                    style={styles.paperContainer}
                >
                    <Container maxWidth="sm" >
                        <Typography
                            component="h3"
                            variant="h4"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Проект небольшой зимней бани
                        </Typography>
                        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
                            Представляем Вам один из проектов небольшой (54 м2) бани по наиболее экономичной технологии строительства.
                            В первую очередь акцент был сделан на универсальность такого строительства и его экономичность.
                        </Typography>
                        <Stack
                            sx={{ pt: 4 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                        {/*    <Button variant="contained">Main call to action</Button>*/}
                        {/*    <Button variant="outlined">Secondary action</Button>*/}
                        </Stack>
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="md">
                    {/* End hero unit */}

                    <MainImageList />

                    {/*    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>*/}
                    {/*        {itemData.map((item) => (*/}
                    {/*            <ImageListItem key={item.img}>*/}
                    {/*                <img*/}
                    {/*                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}*/}
                    {/*                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}*/}
                    {/*                    alt={item.title}*/}
                    {/*                    loading="lazy"*/}
                    {/*                />*/}
                    {/*            </ImageListItem>*/}
                    {/*        ))}*/}
                    {/*    </ImageList>*/}
                </Container>
            </main>
            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <Typography variant="h6" align="center" gutterBottom>
                    Footer
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    Something here to give the footer a purpose!
                </Typography>
                <Copyright />
            </Box>
            {/* End footer */}
        </ThemeProvider>
    );
}