import React, { Component } from 'react';
import ResponsiveAppBar from './ResponsiveAppBar';
import  Album from './Album';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <div>
            <ResponsiveAppBar />
            <Album/>
      </div>
    );
  }
}
