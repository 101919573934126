import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function MainImageList() {
    return (
        <ImageList sx={{ mx: "auto", width: 600, height: 600 }} cols={4} rowHeight={164}>
            {itemData.map((item) => (
                <ImageListItem key={item.img}>
                    <img

                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`url(${item.img})?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}



const itemData = [
    {
        img: require('../Images/Image19.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image20.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image21.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image23.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image25.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image28.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image29.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image30.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image31.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image32.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image33.png').default,
        title: 'Breakfast',
    },
    {
        img: require('../Images/Image34.png').default,
        title: 'Breakfast',
    },
];
